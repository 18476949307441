import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import hero from '../../images/issues/transportation/transportation-hero.webp'
import accomplishment1A from '../../images/issues/transportation/transportation-accomplishment-1a.webp'
import accomplishment1B from '../../images/issues/transportation/transportation-accomplishment-1b.webp'
import accomplishment1C from '../../images/issues/transportation/transportation-accomplishment-1c.webp'
import accomplishment1D from '../../images/issues/transportation/transportation-accomplishment-1d.webp'
import accomplishment1E from '../../images/issues/transportation/transportation-accomplishment-1e.webp'
import accomplishment2 from '../../images/issues/transportation/transportation-accomplishment-2.webp'

const IssuesTransportationPage = ({ location }) => (
  <Layout>
    <Seo location={location} title="Issues - Transportation" />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 xl:px-0">
        <div>
          <img
              className="w-full max-h-96 object-cover object-pos-0-40" // TODO: figure out aspect ratio -- trying to set 2:1
              src={hero}
              alt=""
            />
        </div>
        <div className="pt-10 max-w-5xl mx-auto">
          <h1 className="font-bold text-6xl">Transportation</h1>
          <p className="text-2xl leading-relaxed">
            The people of Rhode Island deserve to have transportation and infrastructure that is multimodal, that is safe and well-designed, and that is accessible to all. Innovative green technologies should also be utilized to make the state prosper. As we plan for the future, we will take into consideration the needs of various users of infrastructure, such as pedestrians, families, children, seniors, bicyclists and those with disabilities.
          </p>
          <p className="text-2xl leading-relaxed">
            Health, well-being, and prosperity for all cannot happen unless people have access to safe and affordable transportation they can count on. Rhode Island's transportation continues to need support from the federal government. It's important to battle inefficient policies that do not provide adequate economic and environmental justice for every resident.
          </p>
          <p className="text-2xl leading-relaxed">
            Safe streets and transportation affects public safety and as a result both the public transportation systems and the streets of Rhode Island need rethinking to maximize public health and safety. Investment in an infrastructure that supports safe pedestrian, bicycle, and motor vehicle traffic would go a long way toward managing problems associated with the pandemic and beyond. Access to safe transportation is essential for all residents in all communities.
          </p>
        </div>
        <div className="max-w-5xl mx-auto pt-10">
          <span className="text-base tracking-widest uppercase">John's Record</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl pb-10">What We've Accomplished Together</h2>
          {/*TODO: L side - bullet point, R side - image - TEXT John when I'm working on this, Sanctuary, John visiting Boston - Mass & Cass, etc.*/}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div>
              <h3 className="font-bold text-xl">Remained An Ardent and Fearless Supporter of Bike Lanes In Providence</h3>
              <p>• In 2021, we stood our ground on the South Water Street improvements that calmed the waterfront and expanded bike lanes, accessibility, and multi-modal transportation for city residents. Avid supporters and neighborhood abutters of the improvements plan cited the previous safety concerns, loud music by the Pedestrian Bridge, the street racing as a result of the two-lane drag strip, ATVs, altered mufflers, droves of motorcycles, and other issues undermining the quality of life and the calmness visitors should feel along the waterfront. Holding our ground on these improvements resulted in a 25-27% reduction in speed, an 84-86% reduction in vehicles traveling faster than 30 MPH, and a 12 MPH reduction in maximum speed. Read our statements <a href="https://council.providenceri.gov/2021/08/18/statement-from-councilman-john-goncalves-regarding-south-water-street-trail/" target="_blank">here</a>, <a href="https://council.providenceri.gov/2021/10/12/councilman-john-goncalves-and-local-residents-express-support-for-the-south-water-street-trail-roadway-project/" target="_blank">here</a>, and <a href="https://council.providenceri.gov/2021/10/21/statement-from-councilman-john-goncalves-regarding-rhode-island-department-of-transportations-meeting-with-the-federal-highway-administration/" target="_blank">here</a>. Our plan was ultimately backed by the Federal Highway Administration and hundreds of advocates. Additionally, following the installation, People for Bikes named South Water Street as one of its "Five Best New Bikeways" nationally <a href="https://www.peopleforbikes.org/news/five-of-the-best-new-bikeways-2021" target="_blank">here</a>.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment1A}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment1B}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment1C}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment1D}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment1E}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Advocated For RIPTA Bus Riders</h3>
              <p>• Stood up for bus riders by being the lead sponsor on legislation opposing the controversial multi-hub bus proposal put forth by the Rhode Island Department of Transportation (RIDOT). The flawed process lacked public input and disproportionately affected low-income communities, seniors, as well as persons with disabilities. Read more <a href="https://ecori.org/2020-9-7-city-council-opposed-bus-hub-plan/">here.</a></p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="max-w-5xl mx-auto pt-10 pb-10">
          <span className="text-base tracking-widest uppercase">Our Policy Platform Moving Forward</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl">The Work We Still Have To Do</h2>

          <p className="text-xl mt-5">Health, well-being, and prosperity for all cannot happen unless people have access to safe and affordable transportation they can count on. Rhode Island's public transport systems need help from a city government to understand its importance. John battles inefficient policies that do not provide adequate economic, environmental, and racial justice for every resident. His initiatives include the addition of dedicated bus lanes, free transit options for those in need, additional bike and pedestrian safety guidelines, and smart investment in infrastructure improvements.</p>

          <h3 className="font-bold text-3xl mt-5">Safe Streets And Transportation Affects Public Safety</h3>
          <p className="text-xl">Both the public transportation systems and the streets of Rhode Island need rethinking to maximize public health and safety. Investment in an infrastructure that supports safe pedestrian, bicycle, and motor vehicle traffic would go a long way toward managing problems associated with the pandemic and beyond. Access to safe transportation is essential for all residents in all communities.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IssuesTransportationPage
